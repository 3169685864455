<template>
  <div id="companyEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="eventData">
              <div class="vx-col w-full">
                <h1>
                  <span v-if="eventData && eventData.id">Uredi dogodek</span>
                  <span v-if="!eventData.id">Dodaj dogodek</span>
                </h1>
              </div>
            </div>


            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Osnovni podatki o dogodku</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="flex mt-5">
                    <div class="w-full">
                      <vs-input class="w-full" label="Ime" v-model="eventData.name"/>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <div class="w-full">
                      <label class="vs-input--label">Opis</label>
                      <vs-textarea class="w-full" rows="6" v-model="eventData.notes"/>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 mt-2">
                    <div>
                      <label class="vs-input--label">Datum dogodka</label>
                      <datepicker placeholder="Datum dogodka" :language="slSI" :format="customDateFormatter" :monday-first="true"
                                  v-model="eventData.date"></datepicker>
                    </div>

                  </div>

                </div>
              </div>
            </div>


            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Naslov lokacije dogodka</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="grid grid-cols-1 mt-5">
                    <label for="online_event" class="vs-input--label mb-1">E-dogodek (online)</label>
                    <vs-switch color="success" v-model="eventData.online_event"
                               id="online_event">
                      <span slot="on">DA</span>
                      <span slot="off">NE</span>
                    </vs-switch>
                  </div>

                  <div v-if="!eventData.online_event">
                  <div class="grid grid-cols-1 mt-5">
                    <label for="companyAddress" class="vs-input--label">Naslov lokacije</label>

                    <gmap-autocomplete
                      ref="companyAddress"
                      id="companyAddress"
                      @place_changed="(data) => { getAddressData(data, 'company') }"
                      placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                      :value="eventData.address.quick_search"
                      :options="{componentRestrictions: { country: 'si' }}"
                      :select-first-on-enter="true"
                      class="vs-inputx vs-input--input normal"></gmap-autocomplete>
                  </div>

                  <div class="collapseBody">

                    <div class="flex mt-5">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Ulica" v-model="eventData.address.route"/>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Hišna številka"
                                  v-model="eventData.address.street_number"/>
                      </div>
                    </div>
                    <div class="flex mt-5">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Naziv pošte" v-model="eventData.address.postal_town"/>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Poštna številka"
                                  v-model="eventData.address.postal_code"/>
                      </div>
                    </div>
                    <div class="flex mt-5">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Občina" v-model="eventData.address.municipality"/>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Država" v-model="eventData.address.country"/>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Evalvacija <small>(glasovanje)</small></h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="grid grid-cols-1 mt-5">
                    <label for="evaluation_in_progress" class="vs-input--label mb-1">Evalvacija je v teku <small>(v tem trenutku obiskovalci dogodka lahko podajo evalvacijo)</small></label>
                    <vs-switch color="success" v-model="eventData.voting_enabled"
                               id="evaluation_in_progress">
                      <span slot="on">DA</span>
                      <span slot="off">NE</span>
                    </vs-switch>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
          <div class="vx-card mt-10" style="overflow: hidden">
            <div class="vx-card__header">
              <h4 class="text-success mb-4">Lokacija dogodka</h4>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body p-0">

                <gmap-map ref="map" :center="center" :zoom="zoom" style="width: 100%; height: 500px">

                  <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false"/>
                  <GmapMarker
                    :key="index"
                    v-for="(item, index) in markers"
                    :position="item.position"
                    :clickable="true"
                    :draggable="false"
                    @click="toggleInfoWindow(item, index)"
                  />
                  <DirectionsRenderer/>
                </gmap-map>
              </div>
            </div>
          </div>

          <div class="vx-card mt-10" style="overflow: hidden" v-if="eventData && eventData.id">
            <div class="vx-card__header">
              <h4 class="text-primary mb-4">Rezultati</h4>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body p-0">

                <div class="flex mt-0 mb-2">
                  <div class="w-full px-5 py-2">
                <vs-alert
                  v-if="eventData.voting_enabled"
                  color="danger"
                  :title="'Glasovanje je trenutno še v teku'"
                  active="true">
                  Vrednosti glasov se lahko še spreminjajo, saj je glasovanje v teku!
                </vs-alert>

                <vs-alert
                  v-if="!eventData.voting_enabled"
                  color="primary"
                  :title="'Glasovanje zaključeno'"
                  active="true">
                  Glasovanje za ta dogodek je zaključeno, novih glasov ni več mogoče oddati.
                </vs-alert>
                  </div>
                </div>

                <EventsStatistics :key="eventData.id + '_stats'" :eventId="eventData.id"></EventsStatistics>

              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="flex mt-10 mb-20">
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
          </vs-col>
        </div>
        <div class="w-1/2">
          <div class="flex">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="danger" icon="delete">Izbriši</vs-button>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="success" icon="save" @click="saveEvent">Shrani</vs-button>
            </vs-col>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
  import vSelect from "vue-select";
  import Fuse from 'fuse.js'
  import Datepicker from 'vuejs-datepicker';
  import {slSI} from 'vuejs-datepicker/dist/locale';
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import EventsStatistics from "./EventsStatistics";

  let directionsService = null;
  let directionsDisplay = null;
  let geoCoder = null;

  export default {
    name: 'event-edit',

    components: {
      DirectionsRenderer,
      'v-select': vSelect,
      'datepicker': Datepicker,
      'flat-pickr': flatPickr,
      EventsStatistics
    },
    props: {
      eventId: Number,
    },
    data() {
      return {
        slSI: slSI,

        center: {lat: 46.0569, lng: 14.5058},
        zoom: 15,

        logo: null,

        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,

        infoOptions: {
          content: '',
          pixelOffset: {
            width: 0,
            height: -35
          }
        },

        markers: [],

        campaignTypes: [],
        campaignTypeId: null,

        unitsData: [],
        unitId: null,

        companyData: {

        },
        eventData: {
          address_id: null,
          created_at: null,
          date: null,
          deleted: 0,
          disabled: 0,
          id: null,
          name: null,
          notes: null,
          online_event: null,
          vote_stats: null,
          votes: null,
          voting_enabled: null,
          address: {
            quick_search: null
          }
        }
      }
    },



    computed: {

    },

    mounted() {
      const _this = this;

      console.log("collapse", _this.$refs.collapsible);

      _this.checkIfGoogleMapsApiLoaded().then((res) => {
        console.log("DONE");

        if (_this.eventId) {
          _this.$vs.loading();
          _this.getEvent();
        } else {
          console.log("kaj pa zdej!?!");
          _this.$vs.loading.close();
        }
      });
    },

    watch: {
      eventId: {
        handler(val, oldVal) {
          console.log("CHANGE!!!!");

          if (val) {
            this.getEvent();
          }
        }
      },
      'eventData.online_event': {
        handler(val, oldVal) {
          const _this = this;
          console.log("online_event!!!!", val);
          if (val) {
            _this.markers = [];
            _this.zoom = 1;
          }
        }
      }
    },

    methods: {
      async checkIfGoogleMapsApiLoaded() {
        const _this = this;

        let promise = new Promise(function (resolve, reject) {
          if (typeof google !== 'undefined') {
            resolve("done")
          } else {
            setTimeout(() => {
              _this.checkIfGoogleMapsApiLoaded()
            }, 400);
          }
        });
      },

      async getEvent() {
        const _this = this;

        _this.$vs.loading();

        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'event-evaluation/' + _this.eventId)
          .then((res) => {

            _this.eventData = res.data.data;
            if(!_this.eventData.address) {
              _this.eventData.address = {
                quick_search: null
              }
            }
            console.log("eventData: ", _this.eventData);
            _this.$vs.loading.close();

            _this.showEventMarker();

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      setMapsDirections() {
        const _this = this;

        if (!directionsService) {
          directionsService = new google.maps.DirectionsService;
          directionsDisplay = new google.maps.DirectionsRenderer;
          directionsDisplay.setMap(_this.$refs.map.$mapObject);
        }
      },


      getAddressData(addressData, addressSection) {
        const _this = this;

        console.log("addressData", addressData);

        document.getElementById(addressSection + "Address").value = addressData.formatted_address;
        _this.eventData.address.quick_search = addressData.formatted_address;
        _this.eventData.address.route = addressData.address_components[1].long_name;
        _this.eventData.address.street_number = addressData.address_components[0].long_name;
        _this.eventData.address.postal_town = addressData.address_components[2].long_name;
        _this.eventData.address.postal_code = addressData.address_components[5].long_name;
        _this.eventData.address.municipality = addressData.address_components[3].long_name;
        _this.eventData.address.country = addressData.address_components[4].long_name;


        if (!directionsService) {
          directionsService = new google.maps.DirectionsService;
          directionsDisplay = new google.maps.DirectionsRenderer;
          directionsDisplay.setMap(_this.$refs.map.$mapObject);
        }

        let position = {
          'lat': addressData.geometry.location.lat(),
          'lng': addressData.geometry.location.lng()
        };

        _this.markers[0] = {
          infoText: 'Lokacija dogodka',
          position: position
        };
        _this.center = position;
        _this.zoom = 15;

        _this.$forceUpdate();

        _this.setMapsDirections();

      },

      showEventMarker() {
        const _this = this;

        geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({'address': _this.eventData.address.quick_search}, function (results, status) {
          if (status === 'OK') {
            _this.center = results[0].geometry.location;

            let position = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            }

            _this.markers.push({
              infoText: 'Lokacija dogodka',
              position: position
            });

            _this.setMapsDirections();

          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }
        });

      },

      customDateFormatter(date) {
        return this.moment(date).format('DD. MM. YYYY');
      },

      saveEvent() {
        const _this = this;

        let eventData = _.omit(_this.eventData, ['address', 'votes', 'vote_stats', 'disabled', 'deleted', 'created_at', 'updated_at']);
        eventData.online_event = _this.eventData.online_event === null || _this.eventData.online_event === false ? false : true;
        eventData.voting_enabled = _this.eventData.voting_enabled === null || _this.eventData.voting_enabled === false ? false : true;
        eventData.date = _this.moment(_this.eventData.date).valueOf();
        eventData.addressObject = _this.eventData.address;


        _this.$vs.loading();

        console.log("event data to save", eventData);

        Vue.prototype.$http.post(Vue.prototype.$config.api.productionNew + 'event-evaluation', eventData)
          .then((res) => {

            console.log("res", res);
            _this.$vs.loading.close();
            _this.$router.push({name: 'EvalvacijaDogodkov'});

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },

      toggleInfoWindow: function (marker, idx) {
        this.infoWindowPos = marker.position;
        this.infoOptions.content = marker.infoText;

        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        } else {
          this.infoWinOpen = true;
          this.currentMidx = idx;

        }
      }

    }
  }
</script>

