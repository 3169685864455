<template>
  <div id="companyContainer">
    <vs-breadcrumb class="mb-5">
      <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li><router-link to="/evalvacija-dogodkov">Dogodki</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">{{eventId ? 'Urejanje' : 'Dodajanje'}} dogodka</li>
    </vs-breadcrumb>

    <EventEdit :eventId="eventId"></EventEdit>
  </div>
</template>

<script>
  import Vue from 'vue';
  import EventEdit from "../../components/Sopotniki/events/EventEdit";

  export default {
    name: 'event-edit',

    components: {
      EventEdit
    },

    data() {
      return {
        eventId: this.$route.params.eventId ? parseInt(this.$route.params.eventId) : null,
      }
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.eventId = null;
      setTimeout(() => {next()}, 100);
    },

    computed: {},

    mounted() {
      const _this = this;
    },

    methods: {}
  }
</script>

